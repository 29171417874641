import React from 'react';
import { Link } from 'gatsby';

import '../styles/privacy-policy.scss';
import HomeLayout from '../blocks/layout/home';
import SEO from '../blocks/layout/seo';

const PrivacyPolicy = () => (
    <HomeLayout>
        <SEO title="Privacy & Policy" keywords={[]} />
        <div>
            <div className="privacy-policy">
                <div className="privacy-content">
                    <div className="tag">LEGAL</div>
                    <h2>
                        <strong>Privacy Policy</strong>
                    </h2>
                    <p>
                        As you may be able to tell, privacy is basically what
                        we're all about. An important part of that is having a
                        policy that people can actually understand. As{' '}
                        <a href="htttps://formwithsheet.com">Form with Sheet</a>{' '}
                        grows and changes, this policy changes with it.
                    </p>
                    <p>
                        Please read the following carefully to understand our
                        views and practices regarding your personal data and how
                        we will treat it. By using our application (the
                        "Service"), you are accepting and consenting to the
                        practices described in this policy.
                    </p>

                    <h4>Third-Party Service</h4>
                    <p>
                        Our(Form with Sheet) Service allows you to build APIs on
                        the top of third-party services("Third-Party Services")
                        without any code logic. In order to take advantage of
                        this feature, you may need to authenticate, register for
                        or log into Third-Party Services Keys through the
                        Service or on the websites of their respective
                        providers. we only will collect relevant information
                        necessary to build the Form with Sheet.
                    </p>

                    <h4>Third-Party Payment Processor</h4>

                    <p>
                        We use a third-party payment processor to process
                        payments made to us. In connection with the processing
                        of such payments, we do not retain any personally
                        identifiable information or any financial information
                        such as credit card numbers. Rather, all such
                        information is provided directly to our third-party
                        processor, Stripe, whose use of your personal
                        information is governed by their privacy policy, which
                        may be viewed at{' '}
                        <a
                            href="https://stripe.com/us/privacy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            https://stripe.com/us/privacy
                        </a>
                        .
                    </p>

                    <h4>Information We Collect</h4>
                    <p>
                        We only ask for personal information when we truly need
                        it to provide the service to you. We collect it by fair
                        and lawful means, with your knowledge and consent. We
                        also let you know why we're collecting it and how it
                        will be used.
                    </p>
                    <p>
                        We only retain collected information for as long as
                        necessary to provide you with your requested service.
                        What data we store, we protect within commercially
                        acceptable means to prevent loss and theft, as well as
                        unauthorized access, disclosure, copying, use or
                        modification.
                    </p>
                    <p>
                        You are free to refuse our request for your personal
                        information, with the understanding that we may be
                        unable to provide you with some or all of our service.
                    </p>
                    <p>
                        Information From third-party services("Third-Party
                        Services") Like Airtable, Google, Twitter & etc. Only
                        those minimal info including your keys and email
                        metadata is encrypted by industry-standard AES256 cipher
                        at rest in our database.
                    </p>
                    <p>
                        The Personal Information that you provide can be
                        accessed at any time through your account or you can
                        contact our customer support.
                    </p>

                    <h4>Third parties</h4>

                    <p>
                        We do use a few third-party scripts with whom we share
                        non-personal data for the following purposes;
                    </p>

                    <ol>
                        <li>Authentication ( Firebase )</li>
                        <li>
                            Page view analytics (
                            <a
                                href="https://simpleanalytics.com/privacy"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Simple Analytics
                            </a>
                            )
                        </li>
                        <li>
                            In-app Crisp chat (
                            <a
                                href="https://crisp.chat/en/privacy/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Crisp
                            </a>
                            )
                        </li>
                        <li>
                            Payment processing (
                            <a
                                href="https://stripe.com/en-in/privacy"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Stripe
                            </a>
                            )
                        </li>
                        <li>
                            OAuth - Google (
                            <a
                                href="https://policies.google.com/privacy"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Privacy policy
                            </a>
                            ,{' '}
                            <a
                                href="https://security.google.com/settings/security/permissions"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Google security settings
                            </a>
                            )
                        </li>
                    </ol>

                    <p>
                        We have ensured that the respective privacy policies of
                        these companies align with our own values.
                    </p>

                    <h4>Security</h4>
                    <p>
                        Form with Sheet takes reasonable steps to protect the
                        Personal Data provided via the Services from loss,
                        misuse, and unauthorized access, disclosure, alteration,
                        or destruction. However, no Internet or email
                        transmission is ever fully secure or error-free. In
                        particular, email sent to or from the Services may not
                        be secure. Therefore, you should take special care in
                        deciding what information you send to us via email.
                        Please keep this in mind when disclosing any Personal
                        Data to Form with Sheet via the Internet.
                    </p>

                    <h4>Contacting Us</h4>
                    <p>
                        If you've got any questions about how we look after
                        personal data, or have any questions regarding our
                        privacy policy, please contact us.
                    </p>
                </div>
            </div>
        </div>
    </HomeLayout>
);

export default PrivacyPolicy;
